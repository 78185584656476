import { FunctionComponent, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import VideoHeader from "../components/ScrollToExplore/VideoHeader";
import ScrollToExplore from "../components/ScrollToExplore/ScrollToExplore";
import VideoSection from "../components/ScrollToExplore/VideoSection";
import CardSection from "../components/CardSection";
import ServicesList from "../components/ServicesList";
import MarquesText from "../components/MarquesText";
import LifeCycle from "../components/LifeCycle";
import axios from "axios";
import { servicesUrl } from "../data/url";
import ServicesDataType, { BottomTag } from "../types/services";

const Services: FunctionComponent = () => {
  const [startupData, setHomeData] = useState<ServicesDataType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(servicesUrl)
      .then((response) => {
        setHomeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const {
    TopTitle,
    Section1,
    BenifitsSection,
    Section2,
    ServicesCycle,
    Section3,
    BottomText,
    BottomDescription,
    BottomTags,
  } = startupData?.data?.attributes ?? {};

  return (
    <div className="relative [background:linear-gradient(159.22deg,_#edf3ff_16.38%,_#effaf1_59.09%,_#edf3ff)] w-full flex flex-col items-start justify-start text-left text-9xl text-navy font-label-3 overflow-hidden">
      <Header />
      {Section1 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[100px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <VideoHeader heading={TopTitle} />
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between text-xs text-grey font-label-3 md:flex-col md:gap-[32px] md:items-start md:justify-center sm:flex-row">
            <VideoSection
              source={Section1?.video.data.attributes.url}
              className="hidden md:flex"
            />
            <ScrollToExplore block1={Section1.description1} />
            <VideoSection
              source={Section1?.video.data.attributes.url}
              className="md:hidden"
            />
          </div>
        </section>
      )}

      {Section2 && <ServicesList ListServices={Section2?.ServicesList} />}

      {ServicesCycle && (
        <div className="self-stretch flex flex-col items-center justify-start gap-[64px] z-[6] text-center text-61xl sm:text-33xl font-h3 sm:gap-[0px] sm:box-border sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
          <div className="relative leading-[89.5%] font-semibold inline-block sm:hidden">
            {ServicesCycle.title}
          </div>
          <div className="hidden sm:flex sm:w-full">
            <LifeCycle
              title={ServicesCycle.title}
              content={ServicesCycle.CycleItem}
              arrowHeight="sm:h-[615px]"
              textWidth="pr-4"
            />
          </div>

          {ServicesCycle && (
            <div className="flex justify-center items-center w-full sm:hidden">
              <div className="flex-1 flex flex-col text-base gap-[24px] pr-4 w-1/4">
                <div className="flex flex-col items-end gap-2">
                  <label className="cursor-pointer rounded-3xl bg-green flex flex-row items-center justify-center py-3 px-4 border-[1px] border-solid border-green">
                    <div className="relative tracking-[0.08em] uppercase font-extrabold">
                      {ServicesCycle.CycleItem[3].title}
                    </div>
                  </label>
                  <p
                    className={`m-0 relative leading-[28px] font-medium inline-block sm:pl-[29.4px] text-right w-3/4 md:w-full`}
                  >
                    {ServicesCycle.CycleItem[3].description}
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center w-[520px] md:w-[300px]">
                <div className="flex-1 flex flex-col items-start text-base justify-start pb-4 gap-[24px]">
                  <div className="flex flex-col items-center gap-2">
                    <p
                      className={`m-0 relative leading-[28px] font-medium inline-block sm:pl-[29.4px] text-center w-3/4 md:w-full`}
                    >
                      {ServicesCycle.CycleItem[0].description}
                    </p>
                    <label className="cursor-pointer rounded-3xl bg-green flex flex-row items-center justify-center py-3 px-4 border-[1px] border-solid border-green">
                      <div className="relative tracking-[0.08em] uppercase font-extrabold">
                        {ServicesCycle.CycleItem[0].title}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <img
                    className="pt-2 mr-[-8px] rotate-[-1deg] md:hidden"
                    src="/arrow-top-left.svg"
                  />
                  <img
                    className="pt-1 mr-[-8px] rotate-[-1deg] hidden md:block"
                    src="/md-top-left.svg"
                  />

                  <div className="px-6 md:px-4">
                    <div className="relative rounded-[50%] bg-green w-[21.8px] h-[21.4px]" />
                  </div>

                  <img
                    className="pt-2 ml-[-8px] rotate-[1deg] md:hidden"
                    src="/arrow-top-right.svg"
                  />
                  <img
                    className="pt-1 mr-[-8px] rotate-[1deg] hidden md:block"
                    src="/md-top-right.svg"
                  />
                </div>
                <div className="flex items-center w-full">
                  <div className="flex justify-start py-4 md:py-2 w-1/4">
                    <div className="relative rounded-[50%] bg-green w-[21.8px] h-[21.4px]" />
                  </div>
                  <div className="flex justify-center w-2/4">
                    <h1 className="m-0 flex-1 relative tracking-[0.08em] leading-[110%] uppercase font-normal font-inherit text-9xl font-label-3 sm:flex-1">
                      {ServicesCycle.centerTitle}
                    </h1>
                  </div>
                  <div className="flex justify-end py-4 md:py-2 w-1/4">
                    <div className="relative rounded-[50%] bg-green w-[21.8px] h-[21.4px]" />
                  </div>
                </div>
                <div className="flex">
                  <img
                    className="pb-2 mr-[-8px] rotate-[1deg] md:hidden"
                    src="/arrow-bottom-left.svg"
                  />
                  <img
                    className="pb-1 mr-[-8px] rotate-[-1deg] hidden md:block"
                    src="/md-bottom-left.svg"
                  />

                  <div className="flex items-end px-6 md:px-4">
                    <div className="relative rounded-[50%] bg-green w-[21.8px] h-[21.4px]" />
                  </div>

                  <img
                    className="pb-1 ml-[-8px] rotate-[-1deg] md:hidden"
                    src="/arrow-bottom-right.svg"
                  />
                  <img
                    className="pb-1 pr-1 mr-[-8px] rotate-[1deg] hidden md:block"
                    src="/md-bottom-right.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start text-base justify-start pt-4 gap-[24px]">
                  <div className="flex flex-col items-center gap-2">
                    <label className="cursor-pointer rounded-3xl bg-green flex flex-row items-center justify-center py-3 px-4 border-[1px] border-solid border-green">
                      <div className="relative tracking-[0.08em] uppercase font-extrabold">
                        {ServicesCycle.CycleItem[2].title}
                      </div>
                    </label>
                    <p
                      className={`m-0 relative leading-[28px] font-medium inline-block sm:pl-[29.4px] text-center w-3/4 md:w-full`}
                    >
                      {ServicesCycle.CycleItem[2].description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col text-base gap-[24px] pl-4 w-1/4">
                <div className="flex flex-col items-start gap-2">
                  <label className="cursor-pointer rounded-3xl bg-green flex flex-row items-center justify-center py-3 px-4 border-[1px] border-solid border-green">
                    <div className="relative tracking-[0.08em] uppercase font-extrabold">
                      {ServicesCycle.CycleItem[1].title}
                    </div>
                  </label>
                  <p
                    className={`m-0 relative leading-[28px] font-medium inline-block sm:pl-[29.4px] text-left w-3/4 md:w-full`}
                  >
                    {ServicesCycle.CycleItem[1].description}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {Section3 && (
        <div className="w-full flex flex-col items-start justify-start box-border z-[2] lg:box-border md:self-stretch md:box-border sm:box-border sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
          <div className="self-stretch rounded-xl [background:linear-gradient(#fff,_#fff),_linear-gradient(107.71deg,_rgba(190,_242,_245,_0.3),_rgba(255,_255,_255,_0.3)_49.48%,_rgba(144,_255,_201,_0.3))] flex flex-col items-start justify-start py-[45px] px-[66px] md:self-stretch md:w-auto md:py-[68px] md:px-[66px] md:box-border">
            <div className="self-stretch flex flex-row flex-wrap items-center justify-between md:flex-col md:items-start md:justify-start md:pt-0 md:pb-0 md:box-border">
              <div className="flex-1 flex flex-col items-start justify-start gap-[40px] md:gap-[30px] md:flex-[unset] md:self-stretch">
                <h3 className="m-0 self-stretch relative text-inherit leading-[110%] font-medium font-inherit">
                  {Section3.title}
                </h3>
                <p className="m-0 w-full relative text-base leading-[28px] font-medium font-label-lowercase inline-block w-[60%] md:w-full">
                  {Section3.description}
                </p>
              </div>
              <img
                className="max-h-full w-[186px] object-cover md:pt-[30px] md:pb-0 md:box-border"
                alt=""
                src="/image3@2x.png"
              />
            </div>
          </div>
        </div>
      )}
      {BenifitsSection && (
        <CardSection
          heading={BenifitsSection.title}
          headerButton={BenifitsSection.text}
          buttonLink={BenifitsSection.link}
          data={BenifitsSection.Benifits}
        />
      )}

      <section className="w-full flex flex-col items-start justify-start box-border gap-[16px] md:box-border sm:pl-4 sm:pr-4 sm:py-16 lg:px-20 md:px-8 py-[100px] px-[125px]">
        <div className="self-stretch flex flex-row flex-wrap items-start justify-between text-left text-31xl text-navy font-h2 md:gap-[68px] md:items-start md:justify-start md:pl-0 md:box-border w-full">
          <div className="flex-1 flex flex-row flex-wrap items-center justify-start gap-[175px] md:gap-[40px] md:pt-0 md:box-border w-full">
            <h3 className="m-0 flex-1 relative text-inherit font-h2 leading-[110%] font-medium w-1/2 sm:w-full">
              {BottomText}
            </h3>
            <p className="m-0 flex-1 relative text-base leading-[28px] font-medium font-label-2 sm:self-stretch w-1/2 sm:w-full">
              {BottomDescription}
            </p>
          </div>
        </div>
      </section>
      {BottomTags && <MarquesText BottomTags={BottomTags} />}
      <Footer />
    </div>
  );
};

export default Services;
