import { useNavigate } from "react-router-dom";
import { ApiListItem } from "../types/home";
import { Benefit } from "../types/startup";
import { TechStack, KeyObjectivesItem } from "../types/useCase";

interface MiniCardSectionProps {
  heading: string;
  headerButton?: string;
  buttonLink?: string;
  data: ApiListItem[] | Benefit[] | TechStack[] | KeyObjectivesItem[];
}

const MiniCardSection: React.FC<MiniCardSectionProps> = ({
  heading,
  headerButton,
  data,
  buttonLink,
}) => {
  const navigate = useNavigate();

  return (
    <section className="self-stretch flex flex-col items-start justify-start pt-[30px] gap-[80px] text-left text-9xl text-navy font-label-3 lg:px-20 lg:box-border md:gap-[80px] sm:gap-[60px] md:px-8 md:box-border sm:pl-4 sm:pr-4 sm:pt-16 sm:box-border">
      <div className="flex flex-row flex-wrap items-center justify-between sm:flex-row sm:gap-[16px]">
        <h1 className="m-0 flex-1 relative text-inherit tracking-[0.08em] leading-[110%] uppercase font-normal font-inherit sm:text-9xl sm:flex-1">
          {heading}
        </h1>
        {headerButton && (
          <button
            className="cursor-pointer hover:shadow-2-2-4-0 [border:none] py-3.5 px-4 bg-pure-white rounded-3xl flex flex-row items-center justify-start gap-[12px]"
            onClick={() => buttonLink && navigate(buttonLink)}
          >
            <b className="relative text-base font-label-3 text-navy text-center">
              {headerButton}
            </b>
            <img
              className="w-3 h-3 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/button-icons1@2x.png"
            />
          </button>
        )}
      </div>
      <div className="flex flex-row  flex-wrap w-[100%] md:justify-start">
        {data.map((res) => (
          <>
            <div
              className="flex basis-1/4 flex-col justify-start lg:w-[205px] sm:w-[120px] lg:h-[164px] sm:h-[79px] mb-12 ml-0, mr-0"
              key={res.title}
            >
              {/* small screens */}
              <div className="hidden lg:flex w-[90px] flex flex-col justify-start gap-[32px]  h-[60%] items-start pr-[26px]">
                <div className="flex-col sm:gap-5 h-full items-start max-w-[90%]">
                  <img
                    className="relative rounded-3xl  sm:flex object-scale-down max-h-[45%] max-w-[90%]"
                    alt=""
                    src={res?.icon?.data?.attributes.url}
                  />
                  <h5 className="m-0 self-stretch relative  leading-[110%] font-bold font-inherit sm:h-[47px] h-[60%] flex items-end text-base  sm:w-[90px] text-sm">
                    {res.title}
                  </h5>
                </div>
              </div>
              {/* end small screens */}

              {/* large screens */}
              <div className="lg:hidden w-[150px] flex flex-col justify-start gap-[32px] sm:gap-3 h-full items-start pr-[25px]">
                <img
                  className="relative rounded-3xl  h-[74px]  object-scale-down max-h-full "
                  alt=""
                  src={res?.icon?.data?.attributes.url}
                />

                <div className="self-stretch flex flex-col items-start justify-start gap-2">
                  <h5 className="m-0 self-stretch relative  leading-[110%] font-bold font-inherit h-[74px]  text-base  lg:w-[205px] sm:w-[120px]">
                    {res.title}
                  </h5>
                  {res.description && (
                    <p className="m-0 self-stretch relative text-base leading-[28px] font-medium">
                      {res.description}
                    </p>
                  )}
                </div>
              </div>
              {/* end small screens */}
              {res.tags && res.tags.length > 0 && (
                <div className="self-stretch flex flex-row flex-wrap gap-[8px] h-[40%] sm:pt-5 justify-start items-end">
                  {res.tags.map((re) => (
                    <button
                      className={`${
                        re.link
                          ? "cursor-pointer hover:shadow-2-2-4-0 rounded-3xl"
                          : "rounded text-xs"
                      } py-2 px-4 bg-[transparent] flex flex-row items-center justify-center border-[1px] border-solid border-navy`}
                      onClick={() => re.link && navigate(re.link)}
                      key={re.name}
                    >
                      <b className="relative text-xs tracking-[0.08em] uppercase font-label-3 text-navy text-left">
                        {re.name}
                      </b>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </section>
  );
};
export default MiniCardSection;
