const baseUrl = "https://api.ideabits-sweden.se/api";
export const homeUrl =
  baseUrl +
  "/home?populate[Section1][populate]=*&populate[Section2][populate]=ListItem.icon&[fields][0]=TopTitle&[fields][1]=MiddleTitle&populate[AIDetails][populate]=*&[fields][2]=projectsTitle&populate[Section3][populate]=ClientInfo.profileImage&populate[Section3][populate]=image&populate[Section3][populate]=Services";
export const privacyPolicyUrl =
  baseUrl +
  "/privacy-policy?fields[0]=TopTitle&populate[PrivacyItems][populate]=*";
export const startupUrl =
  baseUrl +
  "/startup-plan?[fields][0]=TopTitle&populate[Section1][populate]=*&populate[MiddleContent][populate]=*&populate[BenifitsSection][populate]=Benifits.icon&populate[Cycle][populate]=*";
export const mvpUrl =
  baseUrl +
  "/mvp?fields[0]=TopTitle&populate[Section1][populate]=*&populate[MiddleContent][populate]=*&populate[Cycle][populate]=*&populate[BenifitsSection][populate]=Benifits.icon";
export const servicesUrl =
  baseUrl +
  "/service?fields[0]=TopTitle&fields[1]=BottomText&fields[2]=BottomDescription&populate[Section1][populate]=*&populate[Section2][populate]=*&populate[BottomTags][populate]=*&populate[Section3][populate]=*&populate[BenifitsSection][populate]=Benifits.icon&populate[ServicesCycle][populate]=*";
export const dedicatedteamUrl =
  baseUrl +
  "/dedicated-team?[fields][0]=TopTitle&populate[Section1][populate]=*&populate[BenifitsSection][populate]=Benifits.icon&populate[DedicatedTeam][populate]=Team.images";
export const extendedteamUrl =
  baseUrl +
  "/extended-team?[fields][0]=TopTitle&populate[Section1][populate]=*&populate[BenifitsSection][populate]=Benifits.icon&populate[ExtendedTeam][populate]=Team.images";
export const genaiUrl =
  baseUrl +
  "/gen-ai?[fields][0]=TopTitle&[fields][1]=MiddleTitle&populate[Section1][populate]=*&populate[ImpactAreas][populate]=Benifits.icon&populate[AIDetails][populate]=*&populate[Section2][populate]=*&populate[CaseStudies][populate]=CaseStudyItem.image";
export const useCase01Url =
  baseUrl +
  "/use-case-01?&populate[Section1][populate]=*&populate[Section2][populate]=*&populate[Section3][populate]=*&populate[Section4][populate]=ListItem.icon&populate[Section5][populate]=*&populate[Section6][populate]=*&populate[Section7][populate]=ListItem.icon";
export const useCase02Url =
  baseUrl +
  "/use-case-02?&populate[Section1][populate]=*&populate[Section2][populate]=*&populate[Section3][populate]=*&populate[Section4][populate]=ListItem.icon&populate[Section5][populate]=*&populate[Section6][populate]=*&populate[Section7][populate]=ListItem.icon";
export const aboutusUrl =
  baseUrl +
  "/about-us?[fields][0]=TopTitle&[fields][1]=TopSubTitle&[fields][2]=MiddleTitle&[fields][3]=MiddleDescription&populate[Section1][populate]=*&populate[Section2][populate]=*&populate[Section3][populate]=TeamMember.image&populate[Section4][populate]=*&populate[sliderImages][populate]=*";
export const headerUrl =
  baseUrl +
  "/header?[fields][0]=text1&[fields][1]=text2&populate[0]=image&populate[1]=MenuItems&populate[2]=menuTopIcon";
export const footerUrl =
  baseUrl +
  "/footer?[fields][0]=title&[fields][1]=subTitle&[fields][2]=buttonText&[fields][3]=version&populate[icon]=*&populate[Address][populate]=*";
