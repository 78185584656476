interface ScrollToExploreProps {
  block1: string;
  block2?: string;
}

const ScrollToExplore: React.FC<ScrollToExploreProps> = ({
  block1,
  block2,
}) => {
  return (
    <div className="w-[50%] md:w-full flex flex-col items-start justify-start gap-[40px] sm:box-border">
      <label className="self-stretch relative tracking-[0.08em] uppercase font-medium">
        + SCROLL TO EXPLORE +
      </label>
      <p className="m-0 self-stretch relative text-base leading-[28px] font-medium text-navy">
        <span className="block">{block1}</span>
        {block2 && (
          <>
            <span className="block">&nbsp;</span>
            <span className="block">{block2}</span>{" "}
          </>
        )}
      </p>
    </div>
  );
};
export default ScrollToExplore;
