import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem } from "../types/header";

interface MenuModalProps {
  title: string;
  menuItems: MenuItem[];
}

const MenuModal = ({ title, menuItems }: MenuModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef: any = useRef(null);

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (event: { target: any }) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.addEventListener("click", closeDropdown);

    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left z-50">
      <button
        className="cursor-pointer hover:shadow-2-2-4-0 [border:none] py-3.5 px-4 bg-pure-white rounded-3xl flex flex-row items-center justify-start gap-[12px]"
        onClick={toggleDropdown}
      >
        <b className="relative text-base font-label-3 text-navy text-center">
          {title}
        </b>
        <img
          className="w-3 h-3 overflow-hidden shrink-0 object-cover"
          alt=""
          src="/button-icons@2x.png"
        />
      </button>
      {isOpen && (
        <div className="absolute top-14 right-1 sm:right-0 font-h2">
          <div className="rounded-xl bg-navy sm:w-[140px] flex flex-col items-start justify-start py-[30px] sm:py-6 px-10 sm:px-6 box-border gap-[84px] sm:min-w-[330px] z-[3] text-pure-white">
            <div className="self-stretch flex justify-end">
              <div
                className="flex flex-row justify-end"
                onClick={(e) => setIsOpen(false)}
              >
                <div className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-row items-start justify-start relative gap-[60px]">
                  <img src="/close.svg" />
                </div>
              </div>
            </div>
            <div className="self-stretch flex w-full justify-start gap-[64px] sm:gap-[30px]">
              <div className="flex flex-row items-start justify-start">
                <div className="flex-1 flex flex-col items-start justify-start gap-[48px] text-31xl sm:text-[28px]">
                  {menuItems.slice(0, 3).map((res) => (
                    <div
                      key={res.id}
                      className={`cursor-pointer [border:none] p-0 bg-[transparent] relative leading-[110%] font-medium font-h1-bold hover:text-teal ${
                        pathname === res.link ? "text-teal" : "text-pure-white"
                      } text-left inline-block w-[224px] sm:w-full`}
                      onClick={() => navigate(res.link)}
                    >
                      {res.itemName}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[29px]">
                {menuItems.slice(3, 8).map((res) => (
                  <div
                    key={res.id}
                    className={
                      res.id == 4
                        ? `cursor-pointer [border:none] p-0 bg-[transparent] w-[224px] relative leading-[110%] font-medium font-h1-bold hover:text-teal text-31xl sm:text-[28px] `
                        : `cursor-pointer [border:none] p-0 bg-[transparent] relative text-base font-bold font-label-lowercase hover:text-teal ${
                            pathname === res.link
                              ? "text-teal"
                              : "text-pure-white"
                          } text-left inline-block`
                    }
                    onClick={() => navigate(res.link)}
                  >
                    {res.itemName}
                  </div>
                ))}
              </div>
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start sm:self-stretch sm:w-auto sm:flex-col sm:gap-[32px] sm:items-start sm:justify-start">
              <div className="flex flex-row flex-wrap items-start justify-start gap-[40px] sm:flex-[unset] sm:self-stretch text-base">
                {menuItems.slice(8, 14).map((res) => (
                  <a
                    key={res.id}
                    className="[text-decoration:none] relative tracking-[0.08em] leading-[18px] uppercase font-bold text-[inherit] hover:text-teal"
                    href={res.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`/${res.itemName
                        .toLowerCase()
                        .replaceAll(" ", "")}.svg`}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MenuModal;
