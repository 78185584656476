import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Section2 } from "../types/aboutUs";
import { CycleItem } from "../types/aboutUs";

interface TimelineTypes {
  MiddleTitle: string | undefined;
  MiddleDescription?: string | undefined;
  Section2?: Section2 | undefined;
  CycleItem: CycleItem[];
}

const Timeline = ({
  MiddleTitle,
  MiddleDescription,
  Section2,
  CycleItem,
}: TimelineTypes) => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start box-border gap-[24px] text-center text-61xl md:text-33xl text-navy font-h3 lg:self-stretch sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
      <div
        className={`self-stretch flex flex-col items-center justify-start ${
          MiddleDescription ? "gap-[64px]" : ""
        }`}
      >
        <h1 className="m-0 self-stretch relative text-inherit leading-[89.5%] font-semibold font-inherit flex items-center justify-center shrink-0 md:flex-1 sm:text-left">
          {MiddleTitle}
        </h1>
        <p className="m-0 w-full relative text-base leading-[28px] font-medium font-button inline-block sm:text-left">
          {MiddleDescription}
        </p>
      </div>
      {Section2?.title && (
        <div className="self-stretch flex text-navy text-9xl px-3 pt-4 justify-center md:justify-start">
          {Section2?.title}
        </div>
      )}
      <VerticalTimeline
        className={`custom-timeline ${
          Section2?.title ? "!py-20" : "!py-14"
        } md:!pb-0 sm:!pb-3`}
      >
        {CycleItem.map((res, index) => (
          <VerticalTimelineElement
            className={`vertical-timeline-element--work !m-0 pl-6 ${
              !res.title ? "h-28 md:h-20" : ""
            } ${index % 2 !== 0 ? "text-right" : "text-left"} md:text-left`}
            iconStyle={{
              background: res?.dotColor ?? res.color,
              borderWidth: 0,
              boxShadow: "none",
              height: 24,
              width: 24,
              marginLeft: -14,
              marginTop: 4,
            }}
            position={index % 2 !== 0 ? "left" : "right"}
            style={{ width: "auto" }}
            key={res.id}
          >
            {res.title && (
              <div
                className={`flex  ${
                  index % 2 !== 0 ? "justify-end" : "justify-start"
                } md:justify-start h-8`}
              >
                <label
                  className={
                    "rounded-3xl flex justify-center px-4 py-1 border-[1px] border-solid border-navy relative tracking-[0.08em] w-auto text-base font-extrabold"
                  }
                  style={{ background: res.pillColor }}
                >
                  {res.title}
                </label>
              </div>
            )}

            <p
              dangerouslySetInnerHTML={{
                __html: (res.description as string) || (res.text as string),
              }}
            ></p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </section>
  );
};
export default Timeline;
