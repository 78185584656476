interface ScrollToLearnMoreProps {
  block1: string;
  block2?: string;
}

const ScrollToLearnMore: React.FC<ScrollToLearnMoreProps> = ({
  block1,
  block2,
}) => {
  return (
    <div className="w-[40%] md:w-full flex flex-col self-start items-start justify-start gap-[40px] sm:box-border">
      <p className="m-0 self-stretch relative text-base leading-[28px] font-medium text-navy">
        <span className="block">{block1}</span>
        {block2 && (
          <>
            <span className="block">&nbsp;</span>
            <span className="block">{block2}</span>{" "}
          </>
        )}
      </p>
      <label className="cursor-pointer self-stretch relative tracking-[0.08em] uppercase font-medium">
        + SCROLL TO LEARN More +
      </label>
    </div>
  );
};
export default ScrollToLearnMore;
