import { CycleItem } from "../types/startup";

type LifeCycleProps = {
  title: string;
  content: CycleItem[];
  arrowHeight?: string;
  textWidth?: string;
};

const LifeCycle: React.FC<LifeCycleProps> = ({
  title,
  content,
  arrowHeight,
  textWidth,
}) => {
  return (
    <section className="text-base flex flex-col items-start justify-start box-border gap-[97px] sm:gap-12 z-[3] md:self-stretch md:w-auto md:box-border sm:box-border">
      <h1 className="m-0 relative text-61xl font-h2 leading-[89.5%] font-semibold font-h3 inline-block w-[648px] max-w-[648px] sm:text-33xl sm:w-full">
        {title}
      </h1>

      <div className="self-stretch flex flex-row flex-wrap items-center justify-between lg:w-full lg:flex-col lg:gap-[64px] lg:items-center lg:justify-between md:w-full md:flex-col sm:flex sm:flex-col sm:gap-[42px]">
        <div className="flex-1 flex flex-row items-start justify-start lg:self-stretch lg:w-auto lg:flex-row lg:flex-[unset] md:flex-[unset] md:self-stretch sm:flex-col sm:gap-[42px] sm:flex-[unset] sm:self-stretch">
          <img
            className={`hidden sm:block absolute pl-2 pt-4 ${arrowHeight}`}
            src="arrow-down.svg"
          />
          {content.slice(0, 2).map((res) => (
            <div
              key={res.id}
              className="flex-1 flex flex-col items-start justify-start gap-[24px] sm:flex-[unset] sm:self-stretch"
            >
              <div className="flex items-center gap-2">
                <div className="relative rounded-[50%] bg-green w-[21.8px] h-[21.4px] hidden sm:block" />
                <label className="rounded-3xl bg-green flex flex-row items-center justify-center py-3 px-4 border-[1px] border-solid border-green">
                  <div className="relative tracking-[0.08em] uppercase font-extrabold">
                    {res.title}
                  </div>
                </label>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start sm:hidden">
                <div className="relative rounded-[50%] bg-green w-[21.8px] h-[21.4px]" />
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full"
                  alt=""
                  src="/vector-74.svg"
                />
              </div>
              <p
                className={`m-0 relative leading-[28px] font-medium inline-block ${textWidth} sm:pl-[29.4px] text-left`}
              >
                {res.description}
              </p>
            </div>
          ))}
        </div>
        <div className="flex-1 flex flex-row items-start justify-start lg:self-stretch lg:w-auto lg:flex-row lg:flex-[unset] md:flex-[unset] md:self-stretch sm:flex-col sm:gap-[42px] sm:flex-[unset] sm:self-stretch">
          {content.slice(2, 4).map((res) => (
            <div
              key={res.title}
              className="flex-1 flex flex-col items-start justify-start gap-[24px] sm:flex-[unset] sm:self-stretch"
            >
              <div className="flex items-center gap-2">
                <div className="relative rounded-[50%] bg-green w-[21.8px] h-[21.4px] hidden sm:block" />
                <label className="rounded-3xl bg-green flex flex-row items-center justify-center py-3 px-4 border-[1px] border-solid border-green">
                  <b className="relative tracking-[0.08em] uppercase">
                    {res.title}
                  </b>
                </label>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start sm:hidden">
                <div className="relative rounded-[50%] bg-green w-[21.8px] h-[21.4px]" />
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full"
                  alt=""
                  src={
                    res === content.slice(2, 4).pop()
                      ? "/vector-741.svg"
                      : "/vector-74.svg"
                  }
                />
              </div>
              <p
                className={`m-0 relative leading-[28px] font-medium inline-block ${textWidth} sm:pl-[29.4px] text-left`}
              >
                {res.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default LifeCycle;
