// export interface SlideData {
//   date: string;
//   title: string;
//   subtitle: string;
//   description: string;
//   services: string[];
//   bgImage: string;
//   ceoMessage: {
//     text: string;
//     ceoName: string;
//     ceoImage: string;
//     linkedinImage: string;
//   };
// }

import { ApiSection3 } from "../types/home";

const SlideShow: React.FC<ApiSection3> = ({
  image,
  period,
  description,
  Services,
  projectSubTitle,
  ClientInfo,
  projectName,
}: ApiSection3) => {
  // const {
  //   date,
  //   title,
  //   subtitle,
  //   description,
  //   bgImage,
  //   services,
  //   ceoMessage: { text, ceoName, ceoImage, linkedinImage },
  // } = slideData;

  const backgroundImageStyle = {
    backgroundImage: `url('${image.data.attributes.url}')`,
  };

  return (
    <div className="self-stretch flex flex-row items-start justify-start text-xs text-pure-white sm:flex-col">
      <div
        style={backgroundImageStyle}
        className="self-stretch flex-1 rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl flex flex-col items-start justify-end py-[31px] px-8 bg-cover bg-no-repeat bg-[top] sm:h-[350px] sm:items-start sm:justify-end sm:rounded-t-xl sm:rounded-b-none sm:p-6 sm:box-border sm:flex-[unset] sm:self-stretch"
      >
        <div className="w-[302.2px] flex flex-col items-start justify-center gap-[20px] sm:self-stretch sm:w-auto sm:h-auto sm:flex-col sm:gap-[16px] sm:items-start sm:justify-end">
          <label className="rounded-3xl bg-teal flex flex-row items-center justify-center py-2 px-4 text-navy border-[1px] border-solid border-teal">
            <b className="relative tracking-[0.08em] uppercase">{period}</b>
          </label>
          <h4 className="m-0 self-stretch relative text-13xl tracking-[0.08em] uppercase font-extrabold font-inherit">
            {projectName}
          </h4>
          <span className="self-stretch relative leading-[150%] font-medium">
            {projectSubTitle}
          </span>
        </div>
      </div>
      <div className="flex-1 rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-none bg-pure-white overflow-hidden flex flex-col items-start justify-start py-[55px] px-10 box-border gap-[32px] min-h-[532px] text-navy sm:rounded-t-none sm:rounded-b-xl sm:py-12 sm:px-6 sm:box-border sm:flex-[unset] sm:self-stretch">
        <p className="m-0 self-stretch relative text-base leading-[28px] font-medium">
          {description}
        </p>
        <div className="self-stretch flex flex-col items-start justify-start gap-[25px] text-grey sm:gap-[16px]">
          <label className="relative tracking-[0.08em] uppercase font-medium">
            {Services.title}
          </label>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px]">
            {Services.tags.map((service, index) => (
              <label
                key={index}
                className="flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-navy rounded text-navy text-xs"
              >
                <b className="relative tracking-[0.08em] uppercase">
                  {service}
                </b>
              </label>
            ))}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px] sm:gap-[16px]">
          <label className="relative tracking-[0.08em] uppercase font-medium text-grey">
            {ClientInfo.title}
          </label>
          <p className="m-0 self-stretch relative leading-[150%] font-medium">
            {ClientInfo.description}
          </p>
          <div className="flex flex-row items-center justify-center gap-[9px]">
            <img
              className="relative rounded-[40.98px] w-[60px] h-[60px] object-cover"
              alt=""
              src={ClientInfo.profileImage.data.attributes.url}
            />
            <label className="relative leading-[110%] font-extrabold text-base">
              {ClientInfo.name}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlideShow;
