interface HeaderWithTwoParaProps {
  heading: string;
  block1: string;
  block2?: string;
}

const HeaderWithTwoPara: React.FC<HeaderWithTwoParaProps> = ({
  heading,
  block1,
  block2,
}) => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start z-[2] md:box-border sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[68px] text-left text-31xl text-navy font-h3 md:gap-[68px] md:items-start md:justify-start md:pl-0 md:box-border w-full">
        <h1 className="m-0 self-stretch relative text-inherit leading-[110%] font-medium font-inherit sm:text-33xl sm:max-w-full">
          {heading}
        </h1>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-between text-base font-label-lowercase md:gap-[40px] md:pt-0 md:box-border w-full">
          <p
            className={`m-0 relative leading-[28px] font-medium inline-block ${
              !block2 ? "w-1/2" : "w-[40%]"
            } md:w-full shrink-0 sm:flex-1 sm:min-w-[300px]`}
          >
            {block1}
          </p>
          {block2 && (
            <p className="m-0 relative leading-[28px] font-medium inline-block w-[40%] md:w-full shrink-0 sm:flex-1 sm:min-w-[300px]">
              {block2}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};
export default HeaderWithTwoPara;
