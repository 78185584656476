interface VideoProps {
  source: string;
  className?: string;
}

const VideoSection: React.FC<VideoProps> = ({ source, className }) => {
  return (
    <div className={`flex justify-center w-[50%] md:w-full ${className}`}>
      <div
        dangerouslySetInnerHTML={{
          __html: `<video class="relative w-[550px] h-[459px] sm:w-full sm:h-[300px]" autoplay loop muted playsinline>
      <source src=${source} type="video/mp4" />
</video>`,
        }}
      />
    </div>
  );
};
export default VideoSection;
