import { FunctionComponent, useEffect, useRef, useState } from "react";
import Parallax from "parallax-js";
import ContactUsForm from "./ContactUsForm";
import axios from "axios";
import { HeaderDataType } from "../types/header";
import { footerUrl, headerUrl } from "../data/url";
import { FooterDataType } from "../types/footer";
import { useLocation, useNavigate } from "react-router-dom";

const Footer: FunctionComponent = () => {
  const sceneEl = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [headerData, setHeaderData] = useState<HeaderDataType>();
  const [footerData, setFooterData] = useState<FooterDataType>();

  useEffect(() => {
    axios
      .get(headerUrl)
      .then((response) => {
        setHeaderData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(footerUrl)
      .then((response) => {
        setFooterData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    if (sceneEl.current) {
      const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
      });

      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <footer
      id="section"
      className="self-stretch flex flex-col items-center justify-start text-left text-xs text-grey font-label-3 sm:self-stretch sm:items-start sm:justify-start sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-4 pt-[100px] px-[125px]"
    >
      <div className="self-stretch overflow-hidden w-full flex flex-row flex-wrap items-center justify-between lg:box-border md:box-border sm:self-stretch sm:flex-col sm:gap-[32px] sm:items-start sm:justify-between sm:box-border">
        {footerData?.data.attributes && (
          <ContactUsForm
            title={footerData?.data.attributes.title}
            subTitle={footerData?.data.attributes.subTitle}
            buttonText={footerData?.data.attributes.buttonText}
          />
        )}
        <div className="flex flex-row items-start justify-start gap-[49px] md:flex-col md:gap-[49px] md:pt-[60px] md:box-border w-[40%] md:w-[20%] sm:flex-row sm:w-auto sm:space-x-12 sm:self-center">
          <div className="flex flex-col items-start justify-start gap-[16px] w-[168px] sm:w-auto shrink-0">
            <div className="self-stretch flex flex-col items-start justify-start gap-[26px] text-center text-base text-navy">
              {headerData?.data.attributes.MenuItems.slice(0, 3).map((res) => (
                <a
                  key={res.id}
                  className={`[text-decoration:none] self-stretch rounded-3xl flex flex-row items-center justify-start gap-[12px] text-[inherit] hover:text-teal  ${
                    pathname === res.link && "text-teal"
                  }`}
                  href={res.link}
                >
                  <b className="relative">{res.itemName}</b>
                </a>
              ))}
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[16px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[26px] text-center text-base text-navy">
              {headerData?.data.attributes.MenuItems.slice(3, 8).map((res) => (
                <a
                  key={res.id}
                  className={`[text-decoration:none] self-stretch rounded-3xl flex flex-row items-center justify-start gap-[12px] text-[inherit] hover:text-teal ${
                    pathname === res.link && "text-teal"
                  }`}
                  href={res.link}
                >
                  <b className="relative">{res.itemName}</b>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-16 lg:box-border md:box-border sm:flex sm:self-stretch sm:w-auto sm:items-start sm:justify-start sm:pt-16 sm:pb-8 sm:box-border">
        <div className="self-stretch flex flex-row items-start justify-between md:flex-row md:gap-[16px] md:items-start md:justify-between sm:flex-col sm:gap-[32px]">
          <div className="w-[647px] flex flex-row items-start justify-between md:flex-row md:gap-[16px] sm:self-stretch sm:w-auto sm:flex-col sm:gap-[32px] sm:items-start sm:justify-between">
            <img
              className="relative w-12 h-12 md:hidden"
              alt=""
              src={footerData?.data.attributes.icon.data.attributes.url}
            />
            <div className="flex flex-row items-start justify-start gap-[16px] sm:flex-col sm:gap-[32px]">
              <div className="flex flex-col items-start justify-start gap-[12px]">
                <label className="relative tracking-[0.08em] uppercase font-bold flex items-center w-[168px] h-4 shrink-0">
                  {footerData?.data.attributes.Address[0].type}
                </label>
                <p className="m-0 relative text-sm leading-[24px] font-medium text-navy flex items-center w-[263px] sm:w-full">
                  <span className="[line-break:anywhere] w-full">
                    <span className="block">
                      {footerData?.data.attributes.Address[0].line1}
                    </span>
                    <span className="block">
                      {footerData?.data.attributes.Address[0].line2}
                    </span>
                    <span className="block">
                      {footerData?.data.attributes.Address[0].line3}
                    </span>
                    <span className="block">
                      {footerData?.data.attributes.Address[0].line4}
                    </span>
                  </span>
                </p>
              </div>
              <div className="flex flex-col items-start justify-start gap-[12px]">
                <label className="relative tracking-[0.08em] uppercase font-bold flex items-center w-[168px] h-4 shrink-0">
                  {footerData?.data.attributes.Address[1].type}
                </label>
                <p className="m-0 relative text-sm leading-[24px] font-medium text-navy flex items-center w-[264px] sm:w-full">
                  <span className="[line-break:anywhere] w-full">
                    <span className="block">
                      {footerData?.data.attributes.Address[1].line1}
                    </span>
                    <span className="block">
                      {footerData?.data.attributes.Address[1].line2}
                    </span>
                    <span className="block">
                      {footerData?.data.attributes.Address[1].line3}
                    </span>
                    <span className="block">
                      {footerData?.data.attributes.Address[1].line4}
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-[383px] flex flex-row items-start justify-between text-navy sm:self-stretch sm:w-auto sm:flex-col sm:gap-[32px] sm:items-start sm:justify-start">
            <div className="w-[120px] flex flex-col items-start justify-start gap-[12px]">
              {headerData?.data.attributes.MenuItems.slice(8, 14).map((res) => (
                <a
                  key={res.id}
                  className="cursor-pointer [text-decoration:none] relative tracking-[0.08em] leading-[18px] uppercase font-bold text-[inherit] flex items-center w-24 h-4 shrink-0 hover:text-teal"
                  href={res.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {res.itemName}
                </a>
              ))}
            </div>
            <div className="flex flex-col items-start justify-start gap-4 ">
              <label className="self-stretch relative tracking-[0.08em] uppercase font-medium flex items-center h-4 shrink-0">
                {footerData?.data.attributes.version}
              </label>
              <div
                className={`[text-decoration:none] self-stretch rounded-3xl flex flex-row items-center justify-start gap-[12px] text-sm cursor-pointer hover:text-teal ${
                  pathname === "/privacy-policy" ? "text-teal" : "text-navy"
                }`}
                onClick={() => navigate("/privacy-policy")}
              >
                <b className="relative">Privacy Policy</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
