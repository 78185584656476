import { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SlideShow from "../components/SlideShow";
import CardSection from "../components/CardSection";
import VideoHeader from "../components/ScrollToExplore/VideoHeader";
import ScrollToExplore from "../components/ScrollToExplore/ScrollToExplore";
import VideoSection from "../components/ScrollToExplore/VideoSection";
import HeaderWithOnePara from "../components/HeaderWithOnePara";
import { DotLoader } from "react-spinners";
import axios from "axios";
import { HomeDataType } from "../types/home";
import { homeUrl } from "../data/url";

const Home: FunctionComponent = () => {
  const [index, setIndex] = useState(0);
  const [homeData, setHomeData] = useState<HomeDataType>();
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    axios
      .get(homeUrl)
      .then((response) => {
        setHomeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const {
    TopTitle,
    Section1,
    Section2,
    Section3,
    AIDetails,
    MiddleTitle,
    projectsTitle,
  } = homeData?.data?.attributes ?? {};

  return loading ? (
    <div className="flex items-center justify-center min-h-screen">
      <DotLoader color="#1D245E" />
    </div>
  ) : (
    <div className="relative [background:linear-gradient(159.22deg,_#edf3ff_16.38%,_#effaf1_59.09%,_#edf3ff)] w-full flex flex-col items-start justify-start text-left text-9xl text-navy font-label-3 overflow-hidden">
      <Header />

      {Section1 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[100px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:px-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <VideoHeader heading={TopTitle} />
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between text-xs text-grey font-label-3 md:flex-col md:gap-[32px] md:items-start md:justify-center sm:flex-row">
            <VideoSection
              source={Section1?.video.data.attributes.url}
              className="hidden md:flex"
            />
            <ScrollToExplore
              block1={Section1.description1}
              block2={Section1.description2}
            />
            <VideoSection
              source={Section1?.video.data.attributes.url}
              className="md:hidden"
            />
          </div>
        </section>
      )}

      {Section2 && (
        <CardSection
          heading={Section2.title}
          headerButton={Section2.subTitle}
          data={Section2.ListItem}
          buttonLink={Section2.buttonLink}
        />
      )}
      {AIDetails && (
        <HeaderWithOnePara
          title={MiddleTitle}
          block1={AIDetails.description1}
          block2={AIDetails.description2}
          textList={AIDetails.UseCases}
          buttonText={AIDetails.buttonText}
          tagTitle={AIDetails.listTitle}
          buttonLink={AIDetails.buttonLink}
        />
      )}

      {Section3 && (
        <div className="self-stretch flex flex-col items-start justify-start gap-[80px] lg:box-border md:box-border sm:gap-[16px] sm:box-border sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between sm:gap-[0px]">
            <h2 className="m-0 relative text-inherit tracking-[0.08em] leading-[110%] uppercase font-normal font-inherit sm:text-33xl">
              {projectsTitle}
            </h2>
            <div className="flex flex-row items-center justify-start gap-[11px] text-right text-xs text-grey sm:flex-1 sm:items-center sm:justify-end sm:pt-16 sm:box-border">
              <span className="relative tracking-[0.08em] uppercase font-medium">
                {index + 1} 0F {Section3.length}
              </span>
              <div>
                <img
                  className={`relative w-9 h-9 overflow-hidden shrink-0 ${
                    index !== 0 ? "cursor-pointer" : ""
                  }`}
                  src={
                    index === 0
                      ? "/iconarrowleft.svg"
                      : "/iconarrowleftactive.svg"
                  }
                  onClick={() => index !== 0 && setIndex(index - 1)}
                />
              </div>
              {Section3.length && (
                <img
                  className={`relative w-9 h-9 overflow-hidden shrink-0 ${
                    index !== Section3.length - 1 ? "cursor-pointer" : ""
                  }`}
                  src={
                    index === Section3.length - 1
                      ? "/iconarrowrightinactive.svg"
                      : "/iconarrowright.svg"
                  }
                  onClick={() =>
                    index !== Section3.length - 1 && setIndex(index + 1)
                  }
                />
              )}
            </div>
          </div>
          {Section3[index] && <SlideShow {...Section3[index]} />}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Home;
