import React, { useMemo } from "react";
import { emailRegex } from "../regex/emailRegex";

interface EmailUnderlineProps {
  sentence: string;
}

const EmailUnderline: React.FC<EmailUnderlineProps> = ({ sentence }) => {
  const underlineEmails = (text: string) => {
    return text.replace(emailRegex, (match) => {
      return `<a
      className="text-[inherit] text-navy"
      href="mailto:info@ideaBits.se"
      target="_blank"
    ><span class="[text-decoration:underline] text-navy">${match}</span></a>`;
    });
  };

  const underlinedSentence = useMemo(() => {
    return { __html: underlineEmails(sentence) };
  }, [sentence]);

  return <div dangerouslySetInnerHTML={underlinedSentence} />;
};

export default EmailUnderline;
