import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Alert from "./Alert";
import { ClipLoader } from "react-spinners";

interface ContactUsFormProps {
  title: string;
  subTitle: string;
  buttonText: string;
}

const ContactUsForm = ({ title, subTitle, buttonText }: ContactUsFormProps) => {
  const form = useRef<HTMLFormElement>(null);
  const [showAlert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    mode: "onBlur",
  });

  const { errors } = formState;

  const sendEmail = () => {
    if (form.current) {
      setLoader(true);
      emailjs
        .sendForm(
          "service_rguvhhs",
          "template_regz4op",
          form.current,
          "ynB1_VEw4A1Gc_Q_r"
        )
        .then(
          (result) => {
            if (form.current) {
              setAlert(true);
              form.current.reset();
              setLoader(false);
              console.log(result.text);
            }
          },
          (error) => {
            setLoader(false);
            console.log(error.text);
          }
        );
    }
  };

  return (
    <>
      {showAlert && (
        <Alert
          type="success"
          message="Message Sent Successfully!"
          duration={5000}
        />
      )}
      <form
        className="flex flex-col items-start justify-start gap-[32px] sm:self-stretch sm:w-auto w-[40%] md:w-[60%] md:pb-4"
        ref={form}
        onSubmit={handleSubmit(sendEmail)}
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <h3 className="m-0 relative text-31xl leading-[110%] font-medium font-h2 text-navy text-left flex items-center w-[551px] h-[72px] shrink-0 sm:self-stretch sm:w-auto">
            {title}
          </h3>
          <p className="m-0 relative text-base leading-[28px] font-medium font-label-3 text-navy text-left flex items-center w-[551px] h-6 shrink-0 sm:self-stretch sm:w-auto">
            {subTitle}
          </p>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
          <input
            className="font-medium font-label-3 text-base bg-[transparent] self-stretch rounded-md flex flex-row items-center justify-start py-[18px] px-4 opacity-[0.5] border-[1px] border-solid border-navy sm:self-stretch sm:w-auto placeholder:text-base"
            placeholder="Full Name*"
            type="text"
            {...register("full_name", { required: "Full name is required" })}
          />
          {errors.full_name && (
            <div className="text-red-600">
              {errors.full_name.message as string}
            </div>
          )}
          <input
            className="font-medium font-label-3 text-base bg-[transparent] self-stretch rounded-md flex flex-row items-center justify-start py-[18px] px-4 opacity-[0.5] border-[1px] border-solid border-navy placeholder:text-base"
            placeholder="Your email*"
            type="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "invalid email address",
              },
            })}
          />
          {errors.email && (
            <div className="text-red-600">{errors.email.message as string}</div>
          )}
          <textarea
            className="bg-[transparent] font-medium font-label-3 text-base self-stretch rounded-md box-border h-[190px] flex flex-row items-start justify-start py-[18px] px-4 opacity-[0.5] border-[1px] border-solid border-navy placeholder:text-base"
            placeholder="A brief message*"
            {...register("message", { required: "Message is required" })}
            maxLength={320}
          />
          {errors.message && (
            <div className="text-red-600">
              {errors.message.message as string}
            </div>
          )}
          <button
            className="cursor-pointer hover:shadow-2-2-4-0 py-[18px] px-6 m-1 bg-pure-white self-stretch rounded-md flex flex-row items-center justify-center"
            type="submit"
            disabled={loader}
          >
            <b className="relative text-base font-label-3 text-navy text-center mr-3">
              {buttonText}
            </b>
            {loader && <ClipLoader color="#1D245E" size={18} />}
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactUsForm;
