import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import StartupPlan from "./pages/StartupPlan";
import Mvp from "./pages/Mvp";
import DedicatedTeam from "./pages/DedicatedTeam";
import ExtendedTeam from "./pages/ExtendedTeam";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import GenAi from "./pages/GenAi";
import UseCase01 from "./pages/UseCase01";
import UseCase02 from "./pages/UseCase02";
// import CaseStudy from "./pages/CaseStudy";
import PrivacyPage from "./pages/PrivacyPolicy";
import ReactGA from "react-ga4";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  ReactGA.initialize("G-LHDPTSPJNM");

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/startup-plan":
        title = "IdeaBits AB - Start Up Plan";
        break;
      case "/mvp-building":
        title = "IdeaBits AB - MVP Building";
        break;
      case "/dedicated-team":
        title = "IdeaBits AB - Dedicated Team";
        break;
      case "/extended-team":
        title = "IdeaBits AB - Extended Team";
        break;
      case "/services":
        title = "IdeaBits AB - Services";
        break;
      case "/about-us":
        title = "IdeaBits AB - About ideaBits";
        break;
      case "/gen-ai":
        title = "IdeaBits AB - Gen AI";
        break;
      case "/gen-ai/finance-usecase":
        title = "IdeaBits AB - Finance use case";
        break;
      case "/gen-ai/hr-usecase":
        title = "IdeaBits AB - HR use case";
        break;
      // case "/case-study":
      //   title = "IdeaBits AB - Case Study";
      //   break;
      case "/privacy-policy":
        title = "IdeaBits AB - Privacy Policy";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/startup-plan" element={<StartupPlan />} />
      <Route path="/mvp-building" element={<Mvp />} />
      <Route path="/dedicated-team" element={<DedicatedTeam />} />
      <Route path="/extended-team" element={<ExtendedTeam />} />
      <Route path="/services" element={<Services />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/gen-ai" element={<GenAi />} />
      <Route path="/gen-ai/finance-usecase" element={<UseCase01 />} />
      <Route path="/gen-ai/hr-usecase" element={<UseCase02 />} />
      {/* <Route path="/case-study" element={<CaseStudy />} /> */}
      <Route path="/privacy-policy" element={<PrivacyPage />} />
    </Routes>
  );
}
export default App;
