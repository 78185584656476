import { FunctionComponent, useEffect, useState } from "react";
import MenuModal from "./MenuModal";
import { useNavigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import axios from "axios";
import { HeaderDataType } from "../types/header";
import { headerUrl } from "../data/url";

const Header: FunctionComponent = () => {
  const navigate = useNavigate();

  const [headerData, setHomeData] = useState<HeaderDataType>();

  useEffect(() => {
    axios
      .get(headerUrl)
      .then((response) => {
        setHomeData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleClick = (event: any) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").slice(1); // Remove the '#' from the href
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [cookieMessageVisible, setCookieMessageVisible] = useState(true);
  const hasAcceptedCookies = localStorage.getItem("hasAcceptedCookies");

  useEffect(() => {
    if (hasAcceptedCookies === "true") {
      setCookieMessageVisible(false);
    }
  }, []);

  const handleAccept = () => {
    setCookieMessageVisible(false);
    localStorage.setItem("hasAcceptedCookies", "true");
  };

  const handleDecline = () => {
    setCookieMessageVisible(false);
    localStorage.setItem("hasAcceptedCookies", "true");
  };
  return (
    <>
      <CookieConsent
        location="top"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="IdeabitsCookieConsent"
        enableDeclineButton
        style={{
          background: "#1d245e",
          alignItems: "center",
          fontSize: 16,
        }}
        onAccept={handleAccept}
        onDecline={handleDecline}
        buttonStyle={{
          color: "#1d245e",
          background: "#fff",
          fontSize: "16px",
          borderRadius: 22,
          paddingRight: 16,
          paddingLeft: 16,
          paddingTop: 14,
          paddingBottom: 14,
        }}
        buttonClasses="sm:w-[40%]"
        buttonWrapperClasses="sm:w-full"
        declineButtonClasses="sm:w-[40%]"
        declineButtonStyle={{
          color: "#fff",
          background: "#1d245e",
          fontSize: "16px",
          borderRadius: 22,
          paddingRight: 16,
          paddingLeft: 16,
          paddingTop: 14,
          paddingBottom: 14,
        }}
      >
        This website uses cookies to ensure you get the best experience on our
        website.
        <a
          aria-label="learn more about cookies"
          role="button"
          href="https://cookiesandyou.com"
          rel="noopener noreferrer nofollow"
          target="_blank"
          className="pl-2 text-green"
        >
          Learn more
        </a>
      </CookieConsent>
      <header
        className={` ${
          cookieMessageVisible ? "mt-[80px] sm:mt-[155px]" : ""
        } self-stretch flex flex-col items-start justify-start py-3.5 px-[125px] lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:items-center sm:justify-between sm:gap-[0px] sm:pl-4 sm:pr-4 sm:box-border`}
      >
        <nav className="m-0 self-stretch flex flex-row items-center justify-between sm:items-center sm:justify-between sm:gap-[0px]">
          <img
            className="relative w-[175px] h-[65.4px] object-cover cursor-pointer"
            alt=""
            src={headerData?.data.attributes.image.data.attributes.url}
            onClick={() => navigate("/")}
          />
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <button className="cursor-pointer hover:shadow-2-2-4-0 [border:none] py-3.5 px-4 bg-navy rounded-full flex flex-row items-center justify-start gap-[12px] sm:hidden">
              <a href="#section" onClick={handleClick} className="no-underline">
                <b className="relative text-base font-label-3 text-pure-white text-center">
                  {headerData?.data.attributes.text1}
                </b>
              </a>
              <img
                className="w-3 h-3 overflow-hidden shrink-0 object-cover hidden"
                alt=""
                src="/button-icons@2x.png"
              />
            </button>

            {headerData?.data.attributes && (
              <MenuModal
                title={headerData?.data.attributes.text2}
                menuItems={headerData?.data.attributes.MenuItems}
              />
            )}
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
