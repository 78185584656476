import { useNavigate } from "react-router-dom";
import { UseCaseLink } from "../types/home";

interface HeaderWithOnePara {
  title?: string;
  block1?: string;
  block2?: string;
  textList: UseCaseLink[];
  buttonText?: string;
  tagTitle?: string;
  buttonLink?: string;
}

const HeaderWithOnePara: React.FC<HeaderWithOnePara> = ({
  title,
  block1,
  block2,
  textList,
  buttonText,
  tagTitle,
  buttonLink,
}) => {
  const navigate = useNavigate();
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[28px] text-left text-61xl text-navy font-h2 lg:box-border md:box-border sm:box-border sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
      <h1 className="m-0 w-full relative text-inherit font-h2 leading-[89.5%] md:leading-[100%] font-semibold font-inherit inline-block sm:text-33xl sm:max-w-full">
        {title}
      </h1>
      <div className="self-stretch flex flex-row flex-wrap justify-between py-[52px] px-0 text-base font-label-3 sm:gap-[32px] sm:pt-0 sm:box-border">
        <div className="w-[50%] sm:w-full flex flex-col items-start justify-start gap-8">
          <p className="m-0 self-stretch relative leading-[28px] font-medium">
            <span className="block">{block1}</span>
            <span className="block">&nbsp;</span>
            <span className="block">{block2}</span>
          </p>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[9px] text-xs text-grey font-label-lowercase">
            {textList.map((res) => (
              <label
                className="flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-navy rounded text-navy text-xs"
                key={res.id}
              >
                <b className="relative tracking-[0.08em] uppercase">
                  {res.text}
                </b>
              </label>
            ))}
          </div>
        </div>

        <div className="flex flex-col items-start justify-start gap-[32px] text-xs w-[30%] sm:w-full">
          {tagTitle && (
            <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
              <label className="rounded-3xl bg-green flex flex-row items-center justify-center py-3 px-4 border-[1px] border-solid border-green">
                <b className="relative tracking-[0.08em] uppercase">
                  {tagTitle}
                </b>
              </label>
            </div>
          )}
          <button
            className="cursor-pointer hover:shadow-2-2-4-0 [border:none] py-3.5 px-4 bg-pure-white rounded-3xl flex flex-row items-center justify-start gap-[12px] sm:pt-3.5 sm:box-border"
            onClick={() => buttonLink && navigate(buttonLink)}
          >
            <b className="relative text-base font-label-3 text-navy text-center">
              {buttonText}
            </b>
            <img
              className="w-3 h-3 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/button-icons1@2x.png"
            />
          </button>
        </div>
      </div>
    </section>
  );
};
export default HeaderWithOnePara;
