import React, { useState, useEffect } from "react";

interface AlertProps {
  type?: "success" | "error" | "warning";
  message: string;
  duration?: number;
}

const Alert: React.FC<AlertProps> = ({
  type = "default",
  message,
  duration = 5000,
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  const alertClasses = `fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-l-4 px-6 py-3 rounded-md text-base z-50 font-bold ${getTypeClasses(
    type
  )} ${visible ? "block" : "hidden"}`;

  return (
    <div className={alertClasses} role="alert">
      <p>{message}</p>
    </div>
  );
};

const getTypeClasses = (type: string) => {
  switch (type) {
    case "success":
      return "text-[#0A3622] bg-[#d1e7dd] border-[#0A3622]";
    case "error":
      return "border-red-500 text-red-700";
  }
};

export default Alert;
