import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import VideoHeader from "../components/ScrollToExplore/VideoHeader";
import ScrollToExplore from "../components/ScrollToExplore/ScrollToExplore";
import VideoSection from "../components/ScrollToExplore/VideoSection";
import CardSection from "../components/CardSection";
import HeaderWithOnePara from "../components/HeaderWithOnePara";
import axios from "axios";
import { genaiUrl } from "../data/url";
import { DotLoader } from "react-spinners";
import GenAIDataType from "../types/genAI";
import Timeline from "../components/Timeline";

const GenAi: FunctionComponent = () => {
  const [genAIData, setHomeData] = useState<GenAIDataType>();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(genaiUrl)
      .then((response) => {
        setHomeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const {
    TopTitle,
    Section1,
    ImpactAreas,
    AIDetails,
    MiddleTitle,
    Section2,
    CaseStudies,
  } = genAIData?.data?.attributes ?? {};

  const generateUseCaseUrl = (key: number) => {
    if (key === 1) {
      return `/gen-ai/hr-usecase`;
    }
    return `/gen-ai/finance-usecase`;
  };

  return loading ? (
    <div className="flex items-center justify-center min-h-screen">
      <DotLoader color="#1D245E" />
    </div>
  ) : (
    <div className="relative [background:linear-gradient(159.22deg,_#edf3ff_16.38%,_#effaf1_59.09%,_#edf3ff)] w-full flex flex-col items-start justify-start text-left text-9xl text-navy font-label-3 overflow-hidden">
      <Header />
      {Section1 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[100px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <VideoHeader heading={TopTitle} />
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between text-xs text-grey font-label-3 md:flex-col md:gap-[32px] md:items-start md:justify-center sm:flex-row">
            <VideoSection source={Section1.video.data.attributes.url} />
            <ScrollToExplore block1={Section1.description1} />
          </div>
        </section>
      )}

      {/* <section className="self-stretch flex flex-col items-start justify-start p-[125px] gap-[64px] z-[2] text-left text-61xl text-navy font-h3 lg:pl-[125px] lg:box-border md:self-stretch md:w-auto md:pt-[60px] md:px-[30px] md:pb-10 md:box-border">
        <h1 className="m-0 relative text-inherit leading-[89.5%] font-semibold font-inherit">
          Use Cases
        </h1>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-between text-31xl lg:flex-row lg:gap-[64px] lg:items-start lg:justify-between md:self-stretch md:w-auto md:flex-row md:gap-[20px] md:items-start md:justify-start sm:self-stretch sm:w-auto">
          <div className="flex-1 rounded-xl bg-pure-white flex flex-col items-start justify-start  max-w-[472px] lg:w-6/12 sm:w-full ">
            <div className="self-stretch flex flex-col items-start justify-start py-[50px] px-8 gap-[80px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[63px]">
                <h3 className="m-0 self-stretch relative text-inherit leading-[110%] font-medium font-inherit">
                  Use Case One
                </h3>
                <p className="m-0 self-stretch relative text-base leading-[28px] font-medium font-button">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam
                </p>
              </div>
              <h5 className="m-0 self-stretch relative text-2xl leading-[110%] font-bold font-button">
                Any other highlights can be added here
              </h5>
            </div>
          </div>
          <div className="flex-1 rounded-xl bg-pure-white flex flex-col items-start justify-start max-w-[472px] lg:w-6/12">
            <div className="self-stretch flex flex-col items-start justify-start py-[50px] px-8 gap-[80px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[63px]">
                <h3 className="m-0 self-stretch relative text-inherit leading-[110%] font-medium font-inherit">
                  Use Case Two
                </h3>
                <p className="m-0 self-stretch relative text-base leading-[28px] font-medium font-button">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam
                </p>
              </div>
              <h5 className="m-0 self-stretch relative text-2xl leading-[110%] font-bold font-button">
                Any other highlights can be added here
              </h5>
            </div>
          </div>
        </div> 
      </section> */}
      <section className="self-stretch flex flex-col items-start justify-start py-[50px] px-[125px] z-[3] text-left text-61xl text-navy font-h3 lg:box-border md:px-[30px] md:box-border">
        <h1 className="m-0 relative text-inherit leading-[89.5%] font-semibold font-inherit md:self-stretch md:w-auto">
          {CaseStudies?.title}
        </h1>
        {CaseStudies?.CaseStudyItem?.map((item, key) => (
          <div
            key={key}
            className="self-stretch flex flex-col items-start justify-start gap-[120px] text-31xl md:self-stretch w-full md:flex-col"
          >
            <div className="self-stretch flex flex-row flex-wrap items-center justify-between lg:gap-[0px] md:flex-col md:gap-[0px] md:items-start md:justify-start w-full">
              <>
                <img
                  className="cursor-pointer flex-1 relative rounded-xl w-[50%] md:w-full overflow-hidden h-[579px] sm:h-[370px] object-contain md:flex-[unset] md:self-stretch sm:self-stretch"
                  alt=""
                  src={item?.image?.data?.attributes?.url}
                  onClick={() => navigate(generateUseCaseUrl(key))}
                />

                <div className="flex flex-col items-start justify-start py-0 pr-0 pl-[70px] gap-[32px] md:pl-0 md:box-border w-[50%] md:w-full">
                  <div className="w-full md:w-full flex flex-col items-start justify-start gap-[32px]">
                    <h3
                      className="m-0 relative text-inherit leading-[110%] font-medium font-inherit flex items-center w-[493px] sm:self-stretch sm:w-auto cursor-pointer"
                      onClick={() => navigate(generateUseCaseUrl(key))}
                    >
                      {item?.title}
                    </h3>
                    <p className="m-0 self-stretch relative text-base leading-[28px] font-medium font-button sm:w-[350px]">
                      {item?.description}
                    </p>
                  </div>
                  <div className="flex flex-row flex-wrap items-start justify-start gap-[9px] text-xs text-grey font-button lg:flex-row">
                    {item?.tags.map((ele, ref) => (
                      <label
                        key={ref}
                        className="flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-navy rounded text-navy text-xs"
                      >
                        <b className="relative tracking-[0.08em] uppercase">
                          {ele}
                        </b>
                      </label>
                    ))}
                  </div>
                </div>
              </>
            </div>
          </div>
        ))}
      </section>
      {ImpactAreas && (
        <CardSection
          heading={ImpactAreas?.title}
          data={ImpactAreas?.Benifits}
        />
      )}
      {AIDetails && (
        <HeaderWithOnePara
          title={MiddleTitle}
          block1={AIDetails?.description1}
          block2={AIDetails?.description2}
          textList={AIDetails?.UseCases}
          buttonText={AIDetails?.buttonText}
          tagTitle={AIDetails?.listTitle}
          buttonLink={AIDetails?.buttonLink}
        />
      )}

      {Section2 && (
        <Timeline MiddleTitle={Section2?.title} CycleItem={Section2.items} />
      )}

      <Footer />
    </div>
  );
};

export default GenAi;
