import Marquee from "react-fast-marquee";
import { BottomTag } from "../types/services";

interface MarquesTextTypes {
  BottomTags: BottomTag[];
}

const MarquesText = ({ BottomTags }: MarquesTextTypes) => {
  return (
    <Marquee className="flex h-[100px]" autoFill>
      {BottomTags.map((category) => (
        <div key={category.id} className="flex items-center justify-center">
          <div className="relative rounded-[50%] bg-green w-[15px] h-[15px]" />
          <div className="relative leading-[110%] px-10">
            {category.tagName}
          </div>
        </div>
      ))}
    </Marquee>
  );
};
export default MarquesText;
