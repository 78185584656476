import { TeamMemberImage } from "../types/extendedTeam";

interface StartUpPlanLifecycleProps {
  title: string;
  contentList: Array<string>;
  roleImages?: { data: TeamMemberImage };
  count?: number;
}

const Team: React.FC<StartUpPlanLifecycleProps> = ({
  title,
  contentList,
  roleImages,
  count,
}) => {
  return (
    <div className="flex flex-col !w-[324px] lgx:w-[305px] md:w-[310px] sm:w-[324px] h-[516px] shadow-[0px_100px_80px_rgba(15,_35,_92,_0.05),_0px_46.4px_36.27px_rgba(15,_35,_92,_0.02)] bg-pure-white rounded-xl [background:linear-gradient(107.71deg,_rgba(190,_245,_202,_0.3),_rgba(215,_228,_255,_0.3)_49.48%,_rgba(255,_255,_255,_0.3))]">
      <div className="p-4">
        <h3 className="m-0 relative text-31xl leading-[110%] font-medium font-h2 text-navy text-left flex items-center shrink-0 sm:self-stretch sm:w-auto">
          {title}
        </h3>
        <ul className="m-0 pl-[21px] m-0 self-stretch relative text-base leading-[28px] font-medium py-12">
          {contentList.map((res) => (
            <div key={res}>
              <li className="mb-0">{res}</li>
            </div>
          ))}
        </ul>
        <div className="flex flex-row items-end justify-start text-center text-base font-label-lowercase h-full">
          <img
            className="h-[159px] absolute bottom-8"
            src={roleImages?.data.attributes.url}
          />
        </div>
      </div>
    </div>
  );
};
export default Team;
