import { useNavigate } from "react-router-dom";
import { ServicesListTypes } from "../types/services";

interface ServicesListProps {
  ListServices: ServicesListTypes[];
}

const ServicesList: React.FC<ServicesListProps> = ({ ListServices }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col self-stretch justify-center sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
      {ListServices.map((res) => (
        <div
          key={res.id}
          className="flex flex-col items-start justify-start py-5 box-border z-[2] lg:box-border md:self-stretch md:box-border sm:box-border"
        >
          <div className="self-stretch rounded-xl [background:linear-gradient(107.71deg,_rgba(190,_242,_245,_0.3),_rgba(255,_255,_255,_0.3)_49.48%,_rgba(144,_255,_201,_0.3)),_#fff] shadow-[0px_100px_80px_rgba(15,_35,_92,_0.05),_0px_46.4px_36.27px_rgba(15,_35,_92,_0.02)] flex flex-col items-start justify-center py-10 px-[60px] md:px-[30px] sm:px-5 gap-[60px]">
            <div className="self-stretch flex flex-row flex-wrap items-center justify-between sm:gap-6">
              <h2 className="m-0 relative text-inherit tracking-[0.08em] leading-[110%] uppercase font-normal font-inherit">
                {res.title1}
              </h2>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[32px] text-31xl font-h3">
              <h3 className="m-0 w-full relative text-inherit leading-[110%] font-medium font-inherit inline-block w-full">
                {res.title2}
              </h3>
              <div className="self-stretch flex flex-wrap justify-between">
                {res.tags && res.tags.length > 0 && (
                  <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[9px] text-xs text-grey font-label-lowercase">
                    {res.tags.map((re) => (
                      <label
                        key={re}
                        className="flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-navy rounded text-navy text-xs"
                      >
                        <b className="relative tracking-[0.08em] uppercase">
                          {re}
                        </b>
                      </label>
                    ))}
                  </div>
                )}
                <button
                  className="cursor-pointer hover:shadow-2-2-4-0 md:mt-5 [border:none] py-3.5 px-4 bg-pure-white rounded-3xl flex flex-row items-center justify-start gap-[12px]"
                  onClick={() => navigate(res.buttonLink)}
                >
                  <b className="relative text-base font-label-lowercase text-navy text-center">
                    {res.buttonText}
                  </b>
                  <img
                    className="w-3 h-3 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/button-icons2@2x.png"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ServicesList;
