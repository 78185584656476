interface VideoHeaderProps {
  heading?: string;
}

const VideoHeader: React.FC<VideoHeaderProps> = ({ heading }) => {
  return (
    <h1 className="m-0 w-full relative leading-[100%] font-medium font-inherit inline-block overflow-hidden text-ellipsis text-61xl sm:text-[42px] font-h2">
      {heading}
    </h1>
  );
};
export default VideoHeader;
