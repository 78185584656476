import { FunctionComponent, useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageHeader from "../components/ScrollToLearnMore/ImageHeader";
import ScrollToLearnMore from "../components/ScrollToLearnMore/ScrollToLearnMore";
import CardSection from "../components/CardSection";
import MiniCardSection from "../components/MiniCardSection";
import axios from "axios";
import { useCase01Url } from "../data/url";
import { DotLoader } from "react-spinners";
import UseCasesDataType from "../types/useCase";
import { Slide, SlideshowRef } from "react-slideshow-image";

const UseCase01: FunctionComponent = () => {
  const [pageData, setHomeData] = useState<UseCasesDataType>();
  const [loading, setLoading] = useState(true);

  const slideRef = useRef<SlideshowRef>(null);

  useEffect(() => {
    axios
      .get(useCase01Url)
      .then((response) => {
        setHomeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
  } = pageData?.data?.attributes ?? {};

  const arrowButtonStyle = {
    marginRight: "20px",
    background: "none",
    cursor: "pointer",
  };

  return loading ? (
    <div className="flex items-center justify-center min-h-screen">
      <DotLoader color="#1D245E" />
    </div>
  ) : (
    <div className="relative [background:linear-gradient(159.22deg,_#edf3ff_16.38%,_#effaf1_59.09%,_#edf3ff)] w-full flex flex-col items-start justify-start text-left text-9xl text-navy font-label-3 overflow-hidden">
      <Header />
      {Section1 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[100px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border ">
          <ImageHeader heading={Section1?.Title} />
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between pt-[70px] sm:pt-[0px] text-xs text-grey font-label-3 md:flex-col md:gap-[32px] md:items-start md:justify-center sm:flex-row">
            <section className="w-[50%] sm:w-[100%] md:w-[100%] sm:pt-16">
              <div className="">
                <div className="each-slide-effect">
                  <img
                    src={Section1?.Image?.data?.attributes?.url}
                    className="self-stretch relative rounded-xl max-w-full overflow-hidden shrink-0 object-cover w-full"
                  />
                </div>
              </div>
            </section>

            <ScrollToLearnMore block1={Section1?.Description} />
          </div>
        </section>
      )}

      {Section2 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[80px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <h3 className="m-0 relative text-31xl leading-[110%] font-medium font-h2 text-navy text-left flex items-center w-[551px] h-[72px] shrink-0 sm:self-stretch sm:w-auto">
            {Section2?.Title}
          </h3>
          <p className="m-0 w-full relative text-base leading-[28px] font-medium font-button inline-block sm:text-left">
            {Section2?.Description}
          </p>
        </section>
      )}

      {Section3 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[70px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <h3 className="m-0 relative text-31xl leading-[110%] font-medium font-h2 text-navy text-left flex items-center w-[551px] h-[72px] shrink-0 sm:self-stretch sm:w-auto">
            {Section3?.Title}
          </h3>
          <p className="m-0 w-full relative text-base leading-[28px] font-medium font-button inline-block sm:text-left">
            {Section3?.Description}
          </p>
        </section>
      )}

      {Section4 && (
        <section className="mt-[0px] w-full">
          <CardSection heading={Section4?.Title} data={Section4?.ListItem} />
        </section>
      )}

      {Section5 && (
        <section className="w-full sm:pt-16 pt-[70px]">
          <div className="px-20 md:px-8 sm:px-4 px-[125px] gap-[80px">
            <h3 className="ml-0 mb-[80px] flex-1 relative text-inherit tracking-[0.08em] leading-[110%] uppercase font-normal font-inherit sm:text-9xl sm:flex-1">
              {Section5?.Title}
              <div className="arrows text-right mt-[-30px]">
                <button
                  type="button"
                  style={arrowButtonStyle}
                  onClick={() => slideRef?.current?.goBack()}
                >
                  <img src="../goprev.svg" />
                </button>
                <button
                  type="button"
                  style={arrowButtonStyle}
                  onClick={() => slideRef?.current?.goNext()}
                >
                  <img src="../gonext.svg" />
                </button>
              </div>
            </h3>

            <Slide ref={slideRef} arrows={false}>
              {Section5?.sliderImages?.data.map((item: any) => (
                <div className="each-slide-effect" key={item?.id}>
                  <img
                    src={item?.attributes?.url}
                    className="self-stretch relative rounded-xl max-w-full overflow-hidden shrink-0 object-cover w-full"
                  />
                </div>
              ))}
            </Slide>
          </div>
        </section>
      )}

      {Section6 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[70px] px-[125px] pb-0 gap-[28px] text-left  text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <h3 className="ml-0 flex-1 relative text-inherit tracking-[0.08em] leading-[110%] uppercase font-normal font-inherit sm:text-9xl sm:flex-1 ">
            {Section6?.Title}
          </h3>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between text-xs text-grey font-label-3 md:flex-col md:gap-[32px] md:items-start md:justify-center sm:flex-row">
            <section className="w-[40%] sm:pt-16 ">
              <div className="">
                <div className="each-slide-effect">
                  <img
                    src={Section6?.Image?.data?.attributes?.url}
                    className="self-stretch relative rounded-xl max-w-full overflow-hidden shrink-0 object-cover w-full"
                  />
                </div>
              </div>
            </section>

            <ScrollToLearnMore block1={Section6?.Description} />
          </div>
        </section>
      )}

      {Section7 && (
        <section className="self-stretch flex flex-col items-start justify-start px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-2 lg:box-border md:pl-8 md:pr-2 md:box-border sm:pl-4 sm:pt-16 sm:pr-1 sm:box-border ">
          <MiniCardSection
            heading={Section7?.Title}
            data={Section7?.ListItem}
          />
          <div className="m-auto bg-white p-[32px] rounded-xl">
            <p className="m-0 self-stretch relative text-base leading-[28px] font-medium text-navy">
              {Section7?.Note}
            </p>
          </div>
        </section>
      )}

      <Footer />
    </div>
  );
};

export default UseCase01;
