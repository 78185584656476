import { FunctionComponent, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import VideoHeader from "../components/ScrollToExplore/VideoHeader";
import axios from "axios";
import { privacyPolicyUrl } from "../data/url";
import { PrivacyPolicyDataType } from "../types/privacyPolicy";
import { DotLoader } from "react-spinners";
import EmailUnderline from "../components/EmailUnderline";

const PrivacyPage: FunctionComponent = () => {
  const [privacyData, setPrivacyData] = useState<PrivacyPolicyDataType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(privacyPolicyUrl)
      .then((response) => {
        setPrivacyData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  return loading ? (
    <div className="flex items-center justify-center min-h-screen">
      <DotLoader color="#1D245E" />
    </div>
  ) : (
    <div className="relative [background:linear-gradient(107.71deg,_#effaf1,_#edf3ff_49.48%,_#fffce3)] w-full flex flex-col items-start justify-start text-left text-9xl text-navy font-label-3 overflow-hidden">
      <Header />
      <section className="w-full flex flex-col items-start justify-center py-[100px] px-[125px] box-border gap-[111px] text-left text-13xl text-navy font-button lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:gap-[64px] sm:py-16 sm:px-4 sm:box-border">
        <VideoHeader heading={privacyData?.data.attributes.TopTitle} />

        {privacyData?.data.attributes.PrivacyItems.map((res) => (
          <div
            key={res.id}
            className="flex flex-col items-start justify-start gap-[30px] sm:pl-4 sm:pr-4 sm:box-border w-[80%] md:w-full"
          >
            <div className="self-stretch relative tracking-[0.08em] uppercase font-extrabold">
              {res.title}
            </div>

            {res.Descriptions.map((re) => (
              <div
                key={re.id}
                className="w-full relative text-base leading-[28px] font-medium inline-block whitespace-pre-line"
              >
                <EmailUnderline sentence={re.text} />
              </div>
            ))}
          </div>
        ))}
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPage;
