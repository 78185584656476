import { FunctionComponent, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import VideoHeader from "../components/ScrollToExplore/VideoHeader";
import VideoSection from "../components/ScrollToExplore/VideoSection";
import ScrollToExplore from "../components/ScrollToExplore/ScrollToExplore";
import HeaderWithTwoPara from "../components/HeaderWithTwoPara";
import CardSection from "../components/CardSection";
import LifeCycle from "../components/LifeCycle";
import axios from "axios";
import { mvpUrl } from "../data/url";
import MVPDataType from "../types/mvp";
import { DotLoader } from "react-spinners";

const MVP: FunctionComponent = () => {
  const [mvpData, setHomeData] = useState<MVPDataType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(mvpUrl)
      .then((response) => {
        setHomeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const { TopTitle, Section1, MiddleContent, BenifitsSection, Cycle } =
    mvpData?.data?.attributes ?? {};

  return loading ? (
    <div className="flex items-center justify-center min-h-screen">
      <DotLoader color="#1D245E" />
    </div>
  ) : (
    <div className="relative [background:linear-gradient(159.22deg,_#edf3ff_16.38%,_#effaf1_59.09%,_#edf3ff)] w-full flex flex-col items-start justify-start text-left text-9xl text-navy font-label-3 overflow-hidden">
      <Header />
      {Section1 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[100px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <VideoHeader heading={TopTitle} />
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between text-xs text-grey font-label-3 md:flex-col md:gap-[32px] md:items-start md:justify-center sm:flex-row">
            <VideoSection source={Section1?.video.data.attributes.url} />
            <ScrollToExplore block1={Section1.description1} />
          </div>
        </section>
      )}

      {MiddleContent && (
        <HeaderWithTwoPara
          heading={MiddleContent?.title}
          block1={MiddleContent.description1}
        />
      )}
      {Cycle && (
        <div className="sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
          <LifeCycle
            title={Cycle.title}
            content={Cycle.CycleItem}
            arrowHeight="xxsm:h-[950px] sm:h-[925px]"
            textWidth="h-[140px] pr-4"
          />
        </div>
      )}
      {BenifitsSection && (
        <CardSection
          heading={BenifitsSection.title}
          headerButton={BenifitsSection.text}
          data={BenifitsSection?.Benifits}
          buttonLink={BenifitsSection.link}
        />
      )}

      <Footer />
    </div>
  );
};

export default MVP;
