import { FunctionComponent, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import VideoHeader from "../components/ScrollToExplore/VideoHeader";
import VideoSection from "../components/ScrollToExplore/VideoSection";
import { Slide } from "react-slideshow-image";
import ScrollToExplore from "../components/ScrollToExplore/ScrollToExplore";
import axios from "axios";
import { aboutusUrl } from "../data/url";
import { DotLoader } from "react-spinners";
import AboutUsDataType from "../types/aboutUs";
import { color } from "../data/color";
import Timeline from "../components/Timeline";

const AboutUs: FunctionComponent = () => {
  const [aboutUsData, setHomeData] = useState<AboutUsDataType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(aboutusUrl)
      .then((response) => {
        setHomeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const {
    TopTitle,
    Section1,
    Section3,
    MiddleTitle,
    Section2,
    sliderImages,
    TopSubTitle,
    MiddleDescription,
    Section4,
  } = aboutUsData?.data?.attributes ?? {};

  return loading ? (
    <div className="flex items-center justify-center min-h-screen">
      <DotLoader color="#1D245E" />
    </div>
  ) : (
    <div className="relative [background:linear-gradient(159.22deg,_#edf3ff_16.38%,_#effaf1_59.09%,_#edf3ff)] w-full flex flex-col items-start justify-start text-left text-9xl text-navy font-label-3 overflow-hidden">
      <Header />
      {Section1 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[100px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <VideoHeader heading={TopTitle} />
          <h5 className="m-0 self-stretch relative text-2xl leading-[110%] font-bold font-label-lowercase">
            {TopSubTitle}
          </h5>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between text-xs text-grey font-label-3 md:flex-col md:gap-[32px] md:items-start md:justify-center sm:flex-row">
            <VideoSection
              source={Section1?.video.data.attributes.url}
              className="hidden md:flex"
            />

            <ScrollToExplore block1={Section1.description1} />

            <VideoSection
              source={Section1?.video.data.attributes.url}
              className="md:hidden"
            />
          </div>
        </section>
      )}
      {sliderImages && (
        <section className="w-full sm:pt-16 pt-[100px]">
          <div className="px-20 md:px-8 sm:px-4">
            <Slide>
              {sliderImages.data.map((res) => (
                <div className="each-slide-effect" key={res.id}>
                  <img
                    src={res.attributes.url}
                    className="self-stretch relative rounded-xl max-w-full overflow-hidden shrink-0 object-cover w-full"
                  />
                </div>
              ))}
            </Slide>
          </div>
        </section>
      )}

      {Section2 && (
        <Timeline
          MiddleTitle={MiddleTitle}
          MiddleDescription={MiddleDescription}
          Section2={Section2}
          CycleItem={Section2?.CycleItem}
        />
      )}

      {/* <section className="self-stretch flex flex-col items-center justify-start py-[100px] px-[125px] gap-[125px] text-center text-31xl text-navy font-h3 md:pl-8 md:pr-8 md:box-border sm:gap-[64px] sm:pl-4 sm:pr-4 sm:box-border">
        <h3 className="m-0 w-full relative text-inherit leading-[110%] font-medium font-inherit inline-block max-w-[950px]">
          Now, we’re a team of 40+ experienced members who are passionate about
          technology and innovation
        </h3>
        <div className="w-[1033px] flex flex-row flex-wrap items-center justify-start gap-[38px] text-2xl font-label-lowercase lg:self-stretch lg:w-auto lg:gap-[32px] lg:items-center lg:justify-between md:self-stretch md:w-auto md:gap-[41px] md:items-center md:justify-between sm:flex-col sm:gap-[48px] sm:items-center sm:justify-between">
          {teamMembers.map((res) => (
            <div
              className="flex flex-col items-start justify-start gap-[16px]"
              key={res.name}
            >
              <img
                className="relative rounded-[124px] w-[174.7px] h-[174.7px] object-cover"
                alt=""
                src={res.imageSrc}
              />
              <div className="flex flex-col items-start justify-start gap-[15px]">
                <h5 className="m-0 relative text-inherit leading-[110%] font-bold font-inherit flex items-center justify-center w-[174.7px] h-[15.9px] shrink-0">
                  {res.name}
                </h5>
                <span className="relative text-xs tracking-[0.08em] uppercase font-medium flex items-center justify-center w-[175px]">
                  {res.role}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section> */}
      <section className="self-stretch flex flex-col items-start justify-start gap-[64px] text-left text-61xl text-navy font-h3 lg:box-border md:box-border sm:gap-[100px] sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
        <h2 className="m-0 relative text-inherit leading-[89.5%] font-semibold font-inherit flex items-center w-[743px] h-[72px] shrink-0 sm:self-stretch sm:w-auto">
          {Section4?.title}
        </h2>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[64px] text-xs font-label-lowercase lg:flex-row lg:gap-[32px] lg:items-start lg:justify-between lg:flex md:flex-col md:gap-[64px] sm:gap-[64px]">
          {Section4?.ListItem.map((res, index) => (
            <div
              key={res.id}
              className="flex-1 flex flex-col items-start justify-start gap-[32px] lg:w-2/5 md:self-stretch md:w-auto md:flex-[unset]"
            >
              <label
                className={`rounded-3xl bg-${color[index]} flex flex-row items-center justify-center py-3 px-4 border-[1px] border-solid border-${color[index]}`}
              >
                <b className="relative tracking-[0.08em] uppercase">
                  {res.title1}
                </b>
              </label>
              <div className="self-stretch flex flex-col items-start justify-start gap-[24px] min-w-[200px] text-2xl">
                <div className="self-stretch flex flex-col items-start justify-start gap-[32px]">
                  <img
                    className="relative rounded-3xl w-[58px] h-[74px] hidden"
                    alt=""
                    src="/cardicon17.svg"
                  />
                  <div className="self-stretch flex flex-col items-start justify-start gap-[22px]">
                    <h5 className="m-0 self-stretch relative text-inherit leading-[110%] font-bold font-inherit">
                      {res.title2}
                    </h5>
                    <p className="m-0 self-stretch relative text-base leading-[28px] font-medium">
                      {res.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-grey">
                {res.tags.map((re) => (
                  <label
                    key={re}
                    className="flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-navy rounded text-navy text-xs"
                  >
                    <b className="relative tracking-[0.08em] uppercase">{re}</b>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
