import { FunctionComponent, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import VideoHeader from "../components/ScrollToExplore/VideoHeader";
import VideoSection from "../components/ScrollToExplore/VideoSection";
import ScrollToExplore from "../components/ScrollToExplore/ScrollToExplore";
import CardSection from "../components/CardSection";
import Team from "../components/Team";
import { Slide } from "react-slideshow-image";
import { responsiveSettings } from "../data/slideshow";
import axios from "axios";
import DedicatedTeamDataType from "../types/extendedTeam";
import { extendedteamUrl } from "../data/url";
import { DotLoader } from "react-spinners";

const ExtendedTeam: FunctionComponent = () => {
  const [extendedTeamData, setHomeData] = useState<DedicatedTeamDataType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(extendedteamUrl)
      .then((response) => {
        setHomeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const { TopTitle, Section1, BenifitsSection, ExtendedTeam } =
    extendedTeamData?.data?.attributes ?? {};

  return loading ? (
    <div className="flex items-center justify-center min-h-screen">
      <DotLoader color="#1D245E" />
    </div>
  ) : (
    <div className="relative [background:linear-gradient(159.22deg,_#edf3ff_16.38%,_#effaf1_59.09%,_#edf3ff)] w-full flex flex-col items-start justify-start text-left text-9xl text-navy font-label-3 overflow-hidden">
      <Header />
      {Section1 && (
        <section className="self-stretch flex flex-col items-start justify-start pt-[100px] px-[125px] pb-0 gap-[28px] text-left text-61xl text-navy font-h2 lg:pl-20 lg:pr-20 lg:box-border md:pl-8 md:pr-8 md:box-border sm:pl-4 sm:pt-16 sm:pr-4 sm:box-border">
          <VideoHeader heading={TopTitle} />
          <div className="self-stretch flex flex-row flex-wrap items-center justify-between text-xs text-grey font-label-3 md:flex-col md:gap-[32px] md:items-start md:justify-center sm:flex-row">
            <VideoSection source={Section1?.video.data.attributes.url} />
            <ScrollToExplore block1={Section1.description1} />
          </div>
        </section>
      )}

      <section className="self-stretch flex flex-col items-start justify-start gap-[80px] text-left text-9xl text-navy font-label-3 lg:box-border md:gap-[80px] md:box-border sm:box-border sm:pl-4 sm:pr-4 sm:pt-16 lg:px-20 md:px-8 pt-[100px] px-[125px]">
        <div className="self-stretch flex flex-row flex-wrap items-center justify-between sm:flex-row sm:gap-[16px]">
          <h2 className="m-0 relative text-61xl font-h2 leading-[89.5%] font-semibold font-inherit">
            {ExtendedTeam?.title}
          </h2>
          {/* <button
            className="cursor-pointer [border:none] py-3.5 px-4 bg-pure-white rounded-3xl flex flex-row items-center justify-start gap-[12px]"
            onClick={() => navigate("/about-us")}
          >
            <b className="relative text-base font-label-3 text-navy text-center">
              View full team
            </b>
            <img
              className="w-3 h-3 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/button-icons1@2x.png"
            />
          </button> */}
        </div>
        <div className="w-full">
          <Slide
            slidesToScroll={1}
            slidesToShow={1}
            indicators={true}
            responsive={responsiveSettings}
          >
            {ExtendedTeam?.Team.map((res) => (
              <div
                className="each-slide-effect justify-center flex"
                key={res.title}
              >
                <Team
                  key={res.id}
                  title={res.title}
                  contentList={res.Responsibilities}
                  roleImages={res.images}
                  count={res.restCount}
                />
              </div>
            ))}
          </Slide>
        </div>
      </section>
      {BenifitsSection && (
        <CardSection
          heading={BenifitsSection?.title}
          data={BenifitsSection?.Benifits}
        />
      )}

      <Footer />
    </div>
  );
};

export default ExtendedTeam;
